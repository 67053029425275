import React from 'react'
import { Link, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import SEO from '../components/seo'
export default function Template({
    data // this prop will be injected by the GraphQL query below.
}) {
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark
    return (
        <Layout>
            <SEO
                title={frontmatter.title}
                description={`Southwestern Adventist University's ${frontmatter.title}`}
            />
            <div className="container mx-auto py-8 px-2 xl:px-0">
                <div className="prose lg:prose-xl">
                    <div className="catalog">
                        {frontmatter.parent && (
                            <div className="flex gap-4 items-center">
                                <Link
                                    to={frontmatter.parent}
                                    className="text-xl tracking-wider block pb-8"
                                >
                                    {frontmatter.parentName}
                                </Link>
                                <a
                                    href={`/pdfs/${frontmatter.parentName.replace(
                                        / /g,
                                        '-'
                                    )}.pdf`}
                                    className="text-xl tracking-wider block pb-8"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Download
                                </a>
                            </div>
                        )}
                        {!frontmatter.parent && (
                            <div className="flex gap-4 items-center">
                                <a
                                    href={`/pdfs/${frontmatter.title.replace(
                                        / /g,
                                        '-'
                                    )}.pdf`}
                                    className="text-xl tracking-wider block pb-8"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Download
                                </a>
                            </div>
                        )}
                        <h1>{frontmatter.title}</h1>
                        <div dangerouslySetInnerHTML={{ __html: html }} />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

Template.propTypes = {
    data: PropTypes.object
}

export const pageQuery = graphql`
    query($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                slug
                title
                parent
                parentName
            }
        }
    }
`
